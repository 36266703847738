import React, { useEffect, useState } from "react";
import { Collapse, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import UseStaticData from "../UseStaticData";
import { updateScreenItemPropertyValues } from "../../../../utils/uieditorHelpers";
import { errorToastify } from "../../../../../../../common/utils/Toastify";

const TableColumnsPreferences = React.memo(
  ({
    itemType,
    itemRef,
    values,
    showStyling,
    setIsDynamic,
    isDynamic,
    name,
    title,
  }) => {
    const [showPreferences, setShowPreferences] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      // console.log(`> useEffect > values?.columns >> ${JSON.stringify(values)}`);
    }, [values]);

    const onValuesChange = ({ value, property }) => {
      console.log(
        `> onValuesChange > value > ${property} -> ${JSON.stringify(value)}`
      );
      const columnNames = value.map((col) => col.dataText);
      if (columnNames.length !== new Set(columnNames).size) {
        errorToastify("Column names must be unique");
        return;
      }

      dispatch(
        updateScreenItemPropertyValues({
          value,
          property,
          itemRef,
        })
      );
    };

    return (
      <div className="sidebar-section">
        <div
          className="sidebar-section-header"
          onClick={() => setShowPreferences((prev) => !prev)}
        >
          <Typography>{title} columns</Typography>
          <span>{`[${showPreferences ? "-" : "+"}]`}</span>
        </div>

        <Collapse in={showPreferences}>
          <UseStaticData
            itemType={itemType}
            updateData={(value, property) =>
              onValuesChange({ value, property })
            }
            options={values?.columns}
            useValuesAttribute={values?.useValuesAttribute}
            setIsDynamic={setIsDynamic}
            isDynamic={isDynamic}
            name={name}
          />
        </Collapse>
      </div>
    );
  }
);
export default TableColumnsPreferences;
