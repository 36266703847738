import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import FileUpload from "../../FileUpload";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { uploadFile } from "../../../../../../../common/helpers/LiveData";

const CELL_MIN_WIDTH = 140;

const InputTableCell = React.memo(
  ({
    type: cellSectionType,
    row,
    col,
    rowIndex,
    valuesData,
    setCellValue,
    shouldAutoFocus,
    readOnly,
  }) => {
    const dispatch = useDispatch();
    const [reRenderKey, setReRenderKey] = useState(null);

    useEffect(() => {
      setReRenderKey(v4());
    }, [col.cellOptions]);

    const CellInputText = (
      <Input
        style={{
          color: "#091540",
          borderRadius: 3,
          position: "relative",
          border: "1px solid #091540",
          fontSize: 12,
          padding: "3px 12px",
          width: "100%",
          minWidth: CELL_MIN_WIDTH * (col?.relWidth || 1),
        }}
        rows={col.textAreaNumRows || 4}
        multiline={col.inputType === "textArea"}
        className={`${readOnly ? "read-only" : ""}`}
        value={
          cellSectionType === "aggregate"
            ? valuesData?.aggregateCells?.[row.id]
            : valuesData?.columns?.[rowIndex]?.[col.id]
        }
        onChange={(e) => {
          e.persist();
          setCellValue &&
            setCellValue(e.target.value, rowIndex, col, cellSectionType);
        }}
        // style={{ width: "100%" }}
        disabled={
          cellSectionType === "aggregate"
            ? row.inputType !== "variable"
            : col.inputType === "computed"
        }
        readOnly={readOnly}
        autoFocus={!!shouldAutoFocus}
        focused={!!shouldAutoFocus}
      />
    );

    const CellDateTime = (
      <Input
        style={{
          color: "#091540",
          borderRadius: 3,
          position: "relative",
          border: "1px solid #091540",
          fontSize: 12,
          padding: "3px 12px",
          width: "100%",
          display: "inline-grid",
          minWidth: CELL_MIN_WIDTH * (col?.relWidth || 1),
        }}
        type="datetime-local"
        className={`${readOnly ? "read-only" : ""}`}
        value={
          cellSectionType === "aggregate"
            ? valuesData?.aggregateCells?.[row.id]
            : valuesData?.columns?.[rowIndex]?.[col.id]
        }
        onChange={(e) => {
          e.persist();
          setCellValue &&
            setCellValue(e.target.value, rowIndex, col, cellSectionType);
        }}
        // style={{ width: "100%" }}
        disabled={
          cellSectionType === "aggregate"
            ? row.inputType !== "variable"
            : col.inputType === "computed"
        }
        readOnly={readOnly}
        autoFocus={!!shouldAutoFocus}
        focused={!!shouldAutoFocus}
      />
    );

    const CellDropdownSelect = (
      <Select
        key={reRenderKey}
        style={{
          color: "#091540",
          borderRadius: 3,
          position: "relative",
          border: "1px solid #091540",
          fontSize: 12,
          padding: "3px 12px",
          width: "100%",
          minWidth: CELL_MIN_WIDTH * (col?.relWidth || 1),
        }}
        className={`${readOnly ? "read-only" : ""}`}
        value={
          cellSectionType === "aggregate"
            ? valuesData?.aggregateCells?.[row.id]
            : valuesData?.columns?.[rowIndex]?.[col.id]
        }
        onChange={(e) => {
          e.persist();
          setCellValue &&
            setCellValue(e.target.value, rowIndex, col, cellSectionType);
        }}
        // style={{ width: "100%" }}
        disabled={
          cellSectionType === "aggregate"
            ? row.inputType !== "variable"
            : col.inputType === "computed"
        }
        readOnly={readOnly}
        autoFocus={!!shouldAutoFocus}
        focused={!!shouldAutoFocus}
      >
        {col.cellOptions?.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );

    const CellCheckbox = (
      // <FormGroup>
      <FormControl style={{ minWidth: CELL_MIN_WIDTH }}>
        <RadioGroup
          className={`${readOnly ? "read-only" : ""}`}
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {col.cellOptions?.map((option, index) => (
            <FormControlLabel
              key={index}
              className={`${readOnly ? "read-only" : ""}`}
              control={
                <Radio
                  name={`${option}-${index}`}
                  value={option}
                  onChange={(e) => {
                    e.persist();
                    setCellValue &&
                      setCellValue(
                        e.target.value,
                        rowIndex,
                        col,
                        cellSectionType
                      );
                  }}
                  // name={option}
                />
              }
              label={
                <Typography className={""} style={{ textAlign: "left" }}>
                  {option}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
        {/* </FormGroup> */}
      </FormControl>
    );

    switch (col.inputType) {
      case "computed":
        return CellInputText;

      case "inputText":
        return CellInputText;

      case "textArea":
        return CellInputText;

      case "dropdownSelect":
        return CellDropdownSelect;

      case "dateTime":
        return CellDateTime;

      case "checkbox":
        return CellCheckbox;

      case "fileUpload":
        return (
          <FileUpload
            id={`${col.id}-${rowIndex}`}
            dispatch={dispatch}
            values={{
              // required: true,
              buttonText: "Click to upload",
              labelHide: true,
              numOfFiles: 1,
              maxFileSize: 2, //(MB)
            }}
            style={{
              button: {
                width: "100%",
                minWidth: CELL_MIN_WIDTH,
                height: 35,

                fontSize: 12,
                fontWeight: 300,
                color: "#ffffff",
                textAlign: "center",
                textTransform: "none",

                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: 5,
                borderColor: "#091540",
                backgroundColor: "#091540",

                /* color: "#ffff",
              cursor: "pointer",
              border: "1px solid #010A43",
              padding: "10px 0",
              fontSize: "12px",
              variant: "filled",
              background: "#010A43",
              "&:hover": {
                color: "#010A43",
              },
              "&:disabled": {
                color: "#ffff",
              }, */
              },
            }}
            onChange={(e) => {
              // e.persist();
              setCellValue && setCellValue(e, rowIndex, col, cellSectionType);
            }}
            uploadFile={uploadFile}
            val={valuesData?.columns?.[rowIndex]?.[col.id]}
            readOnly={readOnly}
          />
        );

      default:
        if (cellSectionType === "aggregate") {
          return CellInputText;
        }
        break;
    }
  }
);

export default InputTableCell;
