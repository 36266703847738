import { removeElements } from "react-flow-renderer";
import { updateWorkflowTask } from "../../utils/workflowHelpers";

import { v4 } from "uuid";
import { debounce } from "lodash";
import {
  getFieldName,
  getFieldValue,
} from "../../../../../common/helpers/helperFunctions";

export const isConnectedTo = (me, canvas, to) => {
  switch (to) {
    case "anyNode":
      return !!canvas.find((node) => node.target === me);

    case "startNode":
      const startNode = canvas.find((node) => node.type === "StartTask")?.id;
      return !!canvas.find(
        (node) => node.source === startNode && node.target === me
      );

    default:
      break;
  }
};

export const globalSetTaskInfo = debounce(
  (
    dispatch,
    e,
    ppty,
    isGrouped,
    activeTask,
    setTaskUpdated,
    additionalOuterProperties = [],
    checkSetupStatus
  ) => {
    try {
      const MAIN_FIELDS = [
        "name",
        "description",
        "configured",
        "useCustomTrigger",
        "triggerType",
        "triggeredByWorkflow",
        "triggeredByWebhook",
        "isDynamicTable",
        "assignedTo",
        "assignTask",
        "escalateTo",
        "escalateTask",
        ...additionalOuterProperties,
      ];

      const info = { ...activeTask };
      const update = {};
      const saveToDB = true; // onBlur || !e.target?.tagName; //  this check prevents on keypress events to cause save to db

      if (!ppty && !isGrouped) {
        ppty = e?.target?.name;
      }

      if (checkSetupStatus) {
        let activeTask_ = activeTask;
        if (isGrouped) {
          Object.entries(e).forEach((entry) => {
            if (MAIN_FIELDS.includes(entry[0])) {
              activeTask_[entry[0]] = entry[1];
            } else {
              activeTask_.properties[entry[0]] = entry[1];
            }
          });
        } else {
          if (MAIN_FIELDS.includes(ppty)) {
            activeTask_[ppty] = getFieldValue(e);
          } else {
            activeTask_.properties[ppty] = getFieldValue(e);
          }
        }

        const status = checkSetupStatus(activeTask_);

        if (typeof status === "boolean") {
          if (isGrouped) {
            e.configured = status;
          } else {
            ppty = getFieldName(e, ppty);
            if (!ppty) {
              alert("Error occured");
              return;
            }

            e = {
              [ppty]: getFieldValue(e),
              configured: status,
            };
            isGrouped = true;
          }
        }
      }

      if (!isGrouped) {
        const val = getFieldValue(e);
        ppty = getFieldName(e, ppty);
        if (!ppty) {
          alert("Error occured");
          return;
        }

        const unsavedTask = !!ppty && !!e?.target?.tagName;
        setTaskUpdated(unsavedTask);

        if (MAIN_FIELDS.includes(ppty)) {
          info[ppty] = val;
          update[ppty] = val;
        } else if (ppty && typeof ppty === "string") {
          info.properties[ppty] = val;
          update[`properties.${ppty}`] = val;
        } else if (e?.target?.name) {
          info.properties[e?.target?.name] = val;
          update[`properties.${e?.target?.name}`] = val;
        }
      } else {
        for (let ppty in e) {
          if (MAIN_FIELDS.includes(ppty)) {
            info[ppty] = e?.[ppty];
            update[ppty] = e?.[ppty];
          } else if (ppty) {
            // info.properties[ppty] = e?.[ppty];
            objectSplitDots(info, `properties.${ppty}`, e?.[ppty]);
            update[`properties.${ppty}`] = e?.[ppty];
          }
        }
        // update = e;
      }

      info.properties.actionType = "datastorage";
      dispatch(updateWorkflowTask(update, info, saveToDB));
    } catch (err) {
      console.log(err);
    }
  },
  800
);

const objectSplitDots = (obj, is, value) => {
  if (typeof is == "string") return objectSplitDots(obj, is.split("."), value);
  else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value);
  else if (is.length == 0) return obj;
  else return objectSplitDots(obj[is[0]], is.slice(1), value);
};

export const doManageVariables = (
  group,
  toVariablesLists,
  variables,
  activeTaskId,
  activeTaskType,
  clear = null
) => {
  if (!variables) return;

  let taskVariables = [...variables];

  //  first grab relevant fields/columns
  const relevantFields = (list) =>
    list.filter(
      (d) =>
        !!d.dataType &&
        d.dataType !== "none" &&
        d.dataType?.[0] !== "none" &&
        !!d.name
    );

  /* add the populated lists to variables */
  Object.entries(toVariablesLists).forEach(([key, list]) => {
    if (!clear) {
      const relevantList = relevantFields(list);

      relevantList.forEach((field) => {
        //  first check if field already exists
        const existsIndex = taskVariables.findIndex(
          (variable) =>
            variable.matching?.valueSourceId === activeTaskId &&
            variable.group?.toLowerCase() === group.toLowerCase() &&
            variable.matching?.valueSourceInput === field.id
        );
        //  if yes, update existing
        if (existsIndex > -1) {
          const updatedVar = {
            ...taskVariables[existsIndex],
            name: field.name,
            dataType: !!field.dataType
              ? Array.isArray(field.dataType)
                ? field.dataType
                : [field.dataType?.toLowerCase()]
              : ["none"],
            parent: activeTaskId,
            matching: {
              //  to fix possible duplicated screen variables issue
              ...taskVariables[existsIndex]?.matching,
              valueSourceType: key,
              valueSourceId: activeTaskId,
            },
          };

          updatedVar.dataType = [
            ...updatedVar.dataType,
            ...(field.type === "fileUpload"
              ? ["file"]
              : field.type === "userPicker"
              ? ["array"]
              : []),
          ];

          taskVariables[existsIndex] = updatedVar;
        } else {
          //  if no, add to variables
          const newVar = {
            id: v4(),
            name: field.name,
            parent: activeTaskId,
            nodeType: activeTaskType,
            dataType: !!field.dataType
              ? Array.isArray(field.dataType)
                ? field.dataType
                : [field.dataType?.toLowerCase()]
              : ["text"],
            group,
            matching: {
              valueSourceType: key,
              valueSourceId: activeTaskId,
              valueSourceInput: field.id,
            },
          };

          newVar.dataType = [
            ...newVar.dataType,
            ...(field.type === "fileUpload" ? ["file"] : []),
          ];

          taskVariables.push(newVar);
        }
      });

      //  remove all similar variables but that's not on this list
      taskVariables = taskVariables.filter(
        (variable) =>
          variable.group !== group ||
          variable.matching?.valueSourceId !== activeTaskId ||
          variable.matching?.valueSourceType !== key ||
          relevantList.find(
            (field) => field.id === variable.matching?.valueSourceInput
          )
      );
    } else {
      //  clear group variables
      taskVariables = taskVariables.filter(
        (variable) =>
          variable.group !== group ||
          variable.matching?.valueSourceId !== activeTaskId ||
          variable.matching?.valueSourceType !== key ||
          (variable.matching?.valueSourceId === activeTaskId &&
            variable.group !== group)
      );
    }
  });

  return taskVariables;
};

export const removeNodeFromWorkflowCanvas = async (
  elementsToRemove,
  elements,
  isFromRemote,
  otherProps
) => {
  let msg, wh;

  //  return if terminal node
  if (
    !!elementsToRemove.find(
      (el) => el.type === "StartTask" || el.type === "EndTask"
    )
  )
    return;

  if (
    !!elementsToRemove.find((el) => {
      if (el.type !== "default") {
        wh = el.type;
        return true;
      } else return false;
    })
  )
    msg = `${wh} node`;
  else msg = "link";

  if (!isFromRemote) {
    const conf = window?.confirm(`Delete this ${msg}?`);
    if (!conf) return;
  }

  const oldEls = elements && [...elements];
  const newEls = removeElements(elementsToRemove, oldEls);
  // const done = await registerUpdate(newEls, elementsToRemove, "del");
  const done = await otherProps.registerUpdate2(
    elementsToRemove,
    newEls,
    newEls,
    "del"
  );

  if (done !== false) otherProps.setElements(newEls);
  //  right hide settings panel
  otherProps._hideRightSidePanel();
};

export const validFormSelfReferenceFields = (variables, activeTaskId) => {
  return (variables || []).filter(
    (v) =>
      v.matching?.valueSourceId === activeTaskId &&
      v.group === "Form" &&
      !v.dataType.includes("none")
  );
};
