import { connect, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import { InputBase } from "@material-ui/core";

import LabelControl from "../LabelControls";
import RequiredEditable from "../RequiredEditable";
import Selector from "../Selector";
import ButtonStyle from "../ButtonStyle";
import // updateComponentAttribute,
// updateScreenItemPropertyValues,
// updateStyles,
"../../../../utils/uieditorHelpers";
import titleCase from "../../../../../../../common/helpers/titleCase";
import ColorPicker from "../../../../../../../common/components/ColorPicker";
import {
  addHideInvoiceTableLabelProp,
  addInvoiceTableBackColorProp,
  addInvoiceTableBorderColorProp,
} from "../../../../../../../../store/actions/properties";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 3,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 10,
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
  inline: {
    display: "inline-flex",
  },

  color: {
    marginTop: 3,
  },
}));

const layout = [
  ["fill", "Filled"],
  ["outline", "Outlined"],
];

const labelSize = [
  ["12", "12"],
  ["14", "14"],
  ["16", "16"],
];

const labelWeight = [
  ["light", "Light"],
  ["regular", "Regular"],
  ["bold", "Bold"],
];

const labelAlignment = [
  ["left", "Left"],
  ["center", "Center"],
  ["right", "Right"],
  ["justify", "Justify"],
];

function InvoiceTableSidebar(props) {
  const classes = useStyles();
  const { style, values, name, id, type, itemRef, showStyling } = props;
  const dispatch = useDispatch();

  const onStyleChange = ({ value, root, property }) => dispatch();
  // updateStyles({
  //   value,
  //   root,
  //   property,
  //   id,
  //   itemRef,
  //   type,
  // })
  const onNameChange = ({ target: { name, value } }) => dispatch();
  // updateComponentAttribute({
  //   attrib: "name",
  //   value,
  //   itemRef,
  // })
  const onValuesChange = ({ value, property }) => dispatch();
  // updateScreenItemPropertyValues({
  //   value,
  //   property,
  //   itemRef,
  //   type,
  // })

  return (
    <div>
      <Typography gutterBottom className={classes.sideHeading}>
        Decision
        <span>
          Name:{" "}
          <InputText
            variant="outlined"
            size="small"
            onChange={onNameChange}
            value={name}
            placeholder="Name"
            style={{ width: "60%" }}
            inputProps={{
              min: 0,
              style: { textAlign: "center" },
              className: classes.input,
            }}
          />
        </span>
      </Typography>
      <Divider />

      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Button Style
        </Typography>

        <Typography gutterBottom className={classes.sectionTitle}>
          Yes Button
        </Typography>

        <ButtonStyle
          dimensions={style?.yesBtn}
          onChange={(value, property, parent) =>
            onStyleChange({ value: value, root: "yesBtn", property: property })
          }
          buttonStyle={style?.yesBtn}
        />
        <Typography
          gutterBottom
          className={classes.sectionTitle}
          style={{ marginTop: 10 }}
        >
          No Button
        </Typography>
        <ButtonStyle
          dimensions={style?.noBtn}
          onChange={(value, property, parent) =>
            onStyleChange({ value: value, root: "noBtn", property: property })
          }
          buttonStyle={style?.noBtn}
        />
      </div>

      <Divider />

      <Divider />
      <div className={classes.section}>
        <LabelControl
          labelValue={values?.label}
          fontSize={style?.label?.fontSize}
          fontWeight={titleCase(style?.label?.fontWeight)}
          textAlign={titleCase(style?.label?.textAlign)}
          labelName={(e) => onValuesChange({ value: e, property: "label" })}
          color={style?.label?.color}
          selectedSize={(e) =>
            onStyleChange({ value: e, root: "label", property: "fontSize" })
          }
          selectedWeight={(e) =>
            onStyleChange({ value: e, root: "label", property: "fontWeight" })
          }
          selectedAlign={(e) =>
            onStyleChange({ value: e, root: "label", property: "textAlign" })
          }
          textColorCallback={(e) =>
            onStyleChange({ value: e, root: "label", property: "color" })
          }
          labelToggleHide={(e) =>
            onValuesChange({ value: e, property: "labelHide" })
          }
          labelHide={values?.labelHide}
        />
      </div>
      <Divider />
      <div className={classes.section}>
        <Typography gutterBottom className={classes.sectionTitle}>
          Text
        </Typography>
        <div className={classes.inline}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Yes Button
          </Typography>

          <InputText
            variant="outlined"
            size="small"
            placeholder="Yes"
            onChange={(e) =>
              onValuesChange({ value: e.target.value, property: "yesBtn" })
            }
            value={values?.yesBtn}
          />
        </div>
        <div className={classes.inline}>
          <Typography gutterBottom className={classes.sectionLabel}>
            No Button
          </Typography>

          <InputText
            variant="outlined"
            value={values?.noBtn}
            size="small"
            placeholder="No"
            onChange={(e) =>
              onValuesChange({ value: e.target.value, property: "noBtn" })
            }
          />
        </div>

        <Divider />
        <Typography gutterBottom className={classes.sectionTitle}>
          Text Style
        </Typography>

        <div className={classes.inline} style={{ marginBottom: 5 }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            Yes
          </Typography>

          <Selector
            items={labelSize}
            key="labelSize"
            itemType={"labelSize"}
            selectedValue={style?.yesBtn?.fontSize}
            selectedSize={(e) =>
              onStyleChange({ value: e, root: "yesBtn", property: "fontSize" })
            }
          />
          <Selector
            items={labelWeight}
            key="labelWeight"
            itemType={"labelWeight"}
            selectedValue={titleCase(style?.yesBtn?.fontWeight)}
            selectedWeight={(e) =>
              onStyleChange({
                value: e,
                root: "yesBtn",
                property: "fontWeight",
              })
            }
          />
          <Selector
            items={labelAlignment}
            key="labelAlignment"
            itemType={"labelAlignment"}
            selectedValue={titleCase(style?.yesBtn?.textAlign)}
            selectedAlign={(e) =>
              onStyleChange({ value: e, root: "yesBtn", property: "textAlign" })
            }
          />
          <ColorPicker
            identity="textColor"
            color={style?.yesBtn?.color}
            textColorCallback={(e) =>
              onStyleChange({ value: e, root: "yesBtn", property: "color" })
            }
          />
        </div>
        <div className={classes.inline} style={{ marginBottom: 5 }}>
          <Typography gutterBottom className={classes.sectionLabel}>
            No
          </Typography>

          <Selector
            items={labelSize}
            key="labelSize"
            itemType={"labelSize"}
            selectedValue={style?.noBtn?.fontSize}
            selectedSize={(e) =>
              onStyleChange({ value: e, root: "noBtn", property: "fontSize" })
            }
          />
          <Selector
            items={labelWeight}
            key="labelWeight"
            itemType={"labelWeight"}
            selectedValue={titleCase(style?.noBtn?.fontWeight)}
            selectedWeight={(e) =>
              onStyleChange({ value: e, root: "noBtn", property: "fontWeight" })
            }
          />
          <Selector
            items={labelAlignment}
            key="labelAlignment"
            itemType={"labelAlignment"}
            selectedValue={titleCase(style?.noBtn?.textAlign)}
            selectedAlign={(e) =>
              onStyleChange({ value: e, root: "noBtn", property: "textAlign" })
            }
          />
          <ColorPicker
            identity="textColor"
            color={style?.noBtn?.color}
            textColorCallback={(e) =>
              onStyleChange({ value: e, root: "noBtn", property: "color" })
            }
          />
        </div>

        <Divider />
        <div className={classes.inline} style={{ marginBottom: 30 }}>
          <RequiredEditable
            required={values?.required}
            setRequired={(v) =>
              onValuesChange({ value: v, property: "required" })
            }
            editable={values?.editable}
            setEditable={(v) =>
              onValuesChange({ value: v, property: "editable" })
            }
          />
        </div>
      </div>
    </div>
  );
}

export default connect(null, {
  addHideInvoiceTableLabelProp,
  addInvoiceTableBackColorProp,
  addInvoiceTableBorderColorProp,
})(InvoiceTableSidebar);
