import { v4 } from "uuid";

export const allElements = {
  advancedElements: [
    {
      id: v4(), // `displayTable-${new Date().getTime()}`,
      name: `displayTable-${new Date().getTime()}`,
      type: "displayTable",
      title: "Display Table",
      icon: "../../../../../../../images/layout.svg",
      default: true,
      dataType: "none",
      active: false,
      style: {},
      label: {},
      text: {},
      values: {},
      children: [],
      // function: handleInvoiceTableClick,
    },
    {
      id: v4(), // `inputTable-${new Date().getTime()}`,
      name: `inputTable-${new Date().getTime()}`,
      type: "inputTable",
      title: "Input Table",
      icon: "../../../../../../../images/layout.svg",
      default: true,
      dataType: "none",
      active: false,
      style: {},
      label: {},
      text: {},
      values: {},
      children: [],
      // function: handleInvoiceTableClick,
    },
    /* {
        id: v4(), // `inputTable-${new Date().getTime()}`,
        name: `inputTable-${new Date().getTime()}`,
        type: "inputTable",
        title: "Input Table",
        icon: "../../../../../../../images/grid-row.svg",
        default: true,
        dataType: "none",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
        // function: handlePricingTableClick,
      }, */
  ],
  basicElements: [
    // {
    //     id: v4(), // `video-${new Date().getTime()}`,
    //     name: `video-${new Date().getTime()}`,
    //     type: "video",
    //     title: "Video",
    //     icon: "../../../../../../images/icons/play.svg",
    //     default: true,
    //     dataType: "none",
    //     active: false,
    //     style: {},
    //     label: {},
    //     text: {},
    //     values: [],
    //     children: [],
    // },
    {
      id: v4(), // `image-${new Date().getTime()}`,
      name: `image-${new Date().getTime()}`,
      type: "image",
      title: "Image",
      icon: "../../../../../../images/icons/image.svg",
      default: true,
      dataType: "none",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    {
      id: v4(), // `text-${new Date().getTime()}`,
      name: `text-${new Date().getTime()}`,
      type: "text",
      title: "Text",
      icon: "../../../../../../images/icons/type.svg",
      default: true,
      dataType: "text",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    {
      id: v4(), // `heading-${new Date().getTime()}`,
      name: `heading-${new Date().getTime()}`,
      type: "heading",
      title: "Heading Text",
      icon: "../../../../../../../images/icons/label-text.svg",
      default: true,
      dataType: "text",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },

    {
      id: v4(), // `pageBreak-${new Date().getTime()}`,
      name: `pageBreak-${new Date().getTime()}`,
      type: "pageBreak",
      title: "Page Break",
      icon: "../../../../../../images/icons/cut.svg",
      default: true,
      dataType: "none",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    // {
    //   id: `form-${new Date().getTime()}`,
    //   name: "",
    //   type: "form",
    //   title: "Form",
    //   icon: "../../../../../../images/icons/bar-chart.svg",
    //   default: true,
    //   dataType: "submit",
    //   active: false,
    //   style: {},
    //   label: {},
    //   text: {},
    //   values: [],
    //   children: [],
    // },
  ],

  // buttonElements: [
  /* [`submit-${new Date().getTime()}`]: {
                type: "submit",
                title: "Submit",
                icon: "../../../../../../../images/icons/buttons.svg",
                default: true,
                dataType: "", 
                active: false,
                style: {},
                label: {},
                text: {},
                values:[],
                children: [],
            }, */
  //   {
  //     id: v4(), // `actionButton-${new Date().getTime()}`,
  //     name: `actionButton-${new Date().getTime()}`,
  //     type: "actionButton",
  //     title: "Action Button",
  //     icon: "../../../../../../../images/icons/buttons.svg",
  //     default: true,
  //     dataType: "button",
  //     active: false,
  //     style: {},
  //     label: {},
  //     text: {},
  //     values: [],
  //     children: [],
  //   },
  //   {
  //     id: v4(), // `decisionButton-${new Date().getTime()}`,
  //     name: `decisionButton-${new Date().getTime()}`,
  //     type: "decisionButton",
  //     title: "Decision Button",
  //     icon: "../../../../../../../images/icons/branch.svg",
  //     default: true,
  //     dataType: "button",
  //     active: false,
  //     style: {},
  //     label: {},
  //     text: {},
  //     values: [],
  //     children: [],
  //   },
  // ],
  inputElements: [
    {
      id: v4(), // `inputText-${new Date().getTime()}`,
      name: `inputText-${new Date().getTime()}`,
      type: "inputText",
      title: "Input Text",
      icon: "../../../../../../../images/icons/type.svg",
      default: true,
      dataType: "text",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    {
      id: v4(), // `textArea-${new Date().getTime()}`,
      name: `textArea-${new Date().getTime()}`,
      type: "textArea",
      title: "Text Area",
      icon: "../../../../../../../images/icons/text-field.svg",
      default: true,
      dataType: "text",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    {
      id: v4(), // `dropdown-${new Date().getTime()}`,
      name: `dropdown-${new Date().getTime()}`,
      type: "dropdown",
      title: "Select dropdown",
      icon: "../../../../../../../images/icons/dropDown.svg",
      default: true,
      dataType: "text",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    {
      id: v4(), // `checkbox-${new Date().getTime()}`,
      name: `checkbox-${new Date().getTime()}`,
      title: "Checkbox",
      type: "checkbox",
      icon: "../../../../../../../images/icons/checkmark.svg",
      default: true,
      dataType: "text",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    /* {
        id: v4(), // `heading-${new Date().getTime()}`,
        name: `heading-${new Date().getTime()}`,
        type: "heading",
        title: "Label Text",
        icon: "../../../../../../../images/icons/label-text.svg",
        default: true,
        dataType: "text",
        active: false,
        style: {},
        label: {},
        text: {},
        values: [],
        children: [],
      }, */

    {
      id: v4(), // `fileUpload-${new Date().getTime()}`,
      name: `fileUpload-${new Date().getTime()}`,
      type: "fileUpload",
      title: "File Upload",
      icon: "../../../../../../images/icons/file-upload.svg",
      default: true,
      dataType: "text",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    {
      id: v4(), // `phoneNumber-${new Date().getTime()}`,
      name: `phoneNumber-${new Date().getTime()}`,
      type: "phoneNumber",
      title: "Phone Number",
      icon: "../../../../../../../images/icons/mobile.svg",
      default: true,
      dataType: "text",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    // {
    //   id: `currency-${new Date().getTime()}`,
    //   name: "",
    //   type: "currency",
    //   title: "Currency",
    //   icon: "../../../../../../../images/icons/naira.svg",
    //   default: true,
    //   dataType: "text",
    //   active: false,
    //   style: {},
    //   label: {},
    //   text: {},
    //   values: [],
    //   children: [],
    // },
    {
      id: v4(), // `dateTime-${new Date().getTime()}`,
      name: `dateTime-${new Date().getTime()}`,
      type: "dateTime",
      title: "Date Time",
      icon: "../../../../../../../images/icons/calendar.svg",
      default: true,
      dataType: "datetime",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    // {
    //     id: v4(), // `dual-${new Date().getTime()}`,
    //     name: `dual-${new Date().getTime()}`,
    //     type: "dual",
    //     title: "Dual",
    //     icon: "../../../../../../../images/icons/dual-text.svg",
    //     default: true,
    // dataType: "none",
    //     active: false,
    //     style: {},
    //     label: {},
    //     text: {},
    // values: [],
    // children: [],
    // },

    // {
    //     id: v4(), // `address-${new Date().getTime()}`,
    //     name: `address-${new Date().getTime()}`,
    //     type: "address",
    //     title: "Address",
    //     icon: "../../../../../../../images/icons/address.svg",
    //     default: true,
    //     dataType: "none",
    //     active: false,
    //     style: {},
    //     label: {},
    //     text: {},
    //     values: [],
    //     children: [],
    // },
    {
      id: v4(), // `signature-${new Date().getTime()}`,
      name: `signature-${new Date().getTime()}`,
      type: "signature",
      title: "Signature",
      icon: "../../../../../../images/icons/edit.svg",
      default: true,
      dataType: "button",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    {
      id: v4(), // `userPicker-${new Date().getTime()}`,
      name: `userPicker-${new Date().getTime()}`,
      type: "userPicker",
      title: "User Picker",
      icon: "../../../../../../images/icons/edit.svg",
      default: true,
      dataType: "user",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
    // number: {
    //   title: "Number",
    //   icon: "../../../../../../../images/icons/number.svg",
    //   counter: {[`number-${new Date().getTime()}`] : { default: true,
    // dataType: "", active: false }},
    // },

    // name: {
    //   title: "Name",
    //   icon: "../../../../../../../images/icons/user.svg",
    //   counter: {[`name-${new Date().getTime()}`] : { default: true,
    // dataType: "", active: false }},
    // },
    // password: {
    //   title: "Password",
    //   icon: "../../../../../../../images/icons/lock.svg",
    //   counter: {[`password-${new Date().getTime()}`] : { default: true,
    // dataType: "", active: false }},
    // },
  ],
  // selectionElements: [
  //   {
  //     id: v4(), // `dropdown-${new Date().getTime()}`,
  //     name: `dropdown-${new Date().getTime()}`,
  //     type: "dropdown",
  //     title: "Select Dropdown",
  //     icon: "../../../../../../../images/icons/dropDown.svg",
  //     default: true,
  //     dataType: "text",
  //     active: false,
  //     style: {},
  //     label: {},
  //     text: {},
  //     values: [],
  //     children: [],
  //   },
  //   {
  //     id: v4(), // `checkbox-${new Date().getTime()}`,
  //     name: `checkbox-${new Date().getTime()}`,
  //     title: "Checkbox",
  //     type: "checkbox",
  //     icon: "../../../../../../../images/icons/checkmark.svg",
  //     default: true,
  //     dataType: "text",
  //     active: false,
  //     style: {},
  //     label: {},
  //     text: {},
  //     values: [],
  //     children: [],
  //   },
  // {
  //     id: v4(), // `radio-${new Date().getTime()}`,
  //     name: `radio-${new Date().getTime()}`,
  //     title: "Radio",
  //     type: "radio",
  //     icon: "../../../../../../../images/icons/radio.svg",
  //     function: callback,
  //     default: true,
  //     dataType: "none",
  //     active: false,
  //     style: {},
  //     label: {},
  //     text: {},
  //     values: [],
  //     children: [],
  // },
  // {
  //     id: v4(), // `toggle-${new Date().getTime()}`,
  //     name: `toggle-${new Date().getTime()}`,
  //     title: "Toggle",
  //     type: "toggle",
  //     icon: "../../../../../../../images/icons/toggle.svg",
  //     function: callback,
  //     default: true,
  //     dataType: "none",
  //     active: false,
  //     style: {},
  //     label: {},
  //     text: {},
  //     values: [],
  //     children: [],
  // },
  // {
  //     id: v4(), // `slider-${new Date().getTime()}`,
  //     name: `slider-${new Date().getTime()}`,
  //     title: "Slider",
  //     type: "slider",
  //     icon: "../../../../../../../images/icons/options.svg",
  //     function: callback,
  //     default: true,
  //     dataType: "none",
  //     active: false,
  //     style: {},
  //     label: {},
  //     text: {},
  //     values: [],
  //     children: [],
  // },
  // [`quantity-${new Date().getTime()}`]: {
  //   title: "Quantity",
  //   type: "quantity",
  //   icon: "../../../../../../../images/icons/toggle.svg",
  //   function: handleClick,
  //   default: true,
  // dataType: "none",
  //   active: false,
  //   style: {},
  //   label: {},
  //   text: {},
  // values: [],
  // children: [],
  // },
  // ],
  navigationElements: [
    {
      id: v4(), // `header-${new Date().getTime()}`,
      name: `header-${new Date().getTime()}`,
      type: "header",
      title: "Header",
      icon: "../../../../../../images/icons/header.svg",
      default: true,
      dataType: "none",
      active: false,
      style: {},
      label: {},
      text: {},
      values: [],
      children: [],
    },
  ],
};

// function extractCustomElements(ce) {
//   const obj = [];
//   ce.forEach((c, i) => {
//     const id = `custom-${new Date().getTime()}-${i}`;
//     obj.push({
//       id: id,
//       type: "custom",
//       title: ce.name,
//       icon: "../../../../../../../images/icons/dropDown.svg",
//       default: true,
//       dataType: "none",
//       active: false,
//       style: {},
//       label: {},
//       text: {},
//       values: [],
//       children: [],
//       customId: id,
//       customName: ce.name,
//     });
//   });

//   return obj;
// }

// if (isSearch) {
//   const searchResults = [];
//   Object.keys(elements)
//     .filter((element) => element !== "searchedElements")
//     .forEach((c) => {
//       Object.keys(elements[c]).forEach((e) => {
//         if (
//           elements[c][e].type.toLowerCase().includes(searchText.toLowerCase())
//         )
//           searchResults.push(elements[c][e]);
//       });
//     });

//   return searchResults;
// }
// return elements[catg];
// };
