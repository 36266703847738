import React, { useState } from "react";
import SidebarNameSection from "../components/SidebarNameSection";
import TableColumnsPreferences from "../components/TableColumnsPreferences";
import SidebarFieldPreferenceSection from "../components/SidebarFieldPreferenceSection";

const DisplayTableSidebar = (props) => {
  const {
    id,
    itemRef,
    type: itemType,
    title,
    name,
    values,
    dataType,
    updateData,
  } = props;

  const [isDynamic, setIsDynamic] = useState(!!name?.startsWith("@"));

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        itemRef={itemRef}
        isDynamic={isDynamic}
        setIsDynamic={setIsDynamic}
      />
      <SidebarFieldPreferenceSection
        itemType={itemType}
        name={name}
        title={title}
        values={{ ...values }}
        dataType={dataType}
        isDynamic={isDynamic}
        setIsDynamic={setIsDynamic}
        updateData={updateData}
      />

      <div className="sidebar-container-scroll">
        <TableColumnsPreferences
          itemType={itemType}
          name={name}
          title={title}
          itemRef={itemRef}
          values={{ ...(values || {}) }}
          setIsDynamic={setIsDynamic}
          isDynamic={isDynamic}
        />
      </div>
    </div>
  );
};

export default DisplayTableSidebar;
